body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.responsive-image {
    background-size: cover;
}

.sticky {
    width: 100%;
    z-index: 1000;
}

.article-container {
    width: 730px;
    margin: auto;
}

.article-container img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.MuiTypography-body1 {
    opacity: 0 !important;
}

.rdw-center-aligned-block * {
    text-align: center !important;
}

.rdw-right-aligned-block * {
    text-align: right !important;
}

.rdw-left-aligned-block * {
    text-align: left !important;
}

.rdw-editor-main {
    min-height: 600px;
    margin-bottom: 10px;
    font-family: 'Exo2' !important;
}

.editor figure {
    margin: 0;
}

.editor .rdw-image-left {
    display: unset;
    float: left;
}
.editor .rdw-image-right {
    display: unset;
    justify-content: unset;
    float: right;
}

.Toastify__toast-body {
    white-space: pre-line;
}

.public-DraftStyleDefault-block {
    margin: 0;
}

.react-datepicker-popper {
    z-index: 1000;
}

@media screen and (max-width: 1000px) {
    .rdw-editor-wrapper {
        width: 820px;
    }

    .article-container {
        width: 820px;
        margin: auto;
    }
}

@media screen and (min-width: 1200px) {
    .rdw-editor-wrapper {
        width: 1030px;
    }

    .article-container {
        width: 1030px;
        margin: auto;
    }
}

@media screen and (min-width: 1900px) {
    .rdw-editor-wrapper {
        width: 1440px;
    }

    .article-container {
        width: 1440px;
        margin: auto;
    }
}

.unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.rdw-image-imagewrapper img {
    width: 100% !important;
    height: auto !important;
}

[data-text='true'] {
    line-height: 22px;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}
