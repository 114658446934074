@font-face {
    font-family: 'Exo2';
    font-display: swap;
    src:
        url('Exo2-Light.ttf') format('truetype'),
        url('Exo2-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Exo2';
    font-display: swap;
    src:
        url('Exo2-SemiBold.ttf') format('truetype'),
        url('Exo2-SemiBold.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Exo2';
    font-display: swap;
    src:
        url('Exo2-Bold.ttf') format('truetype'),
        url('Exo2-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Exo2';
    font-display: swap;
    src:
        url('Exo2-Italic.ttf') format('truetype'),
        url('Exo2-Italic.otf') format('otf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Exo2';
    font-display: swap;
    src:
        url('Exo2-SemiBoldItalic.ttf') format('truetype'),
        url('Exo2-SemiBoldItalic.otf') format('otf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Exo2';
    font-display: swap;
    src:
        url('Exo2-BoldItalic.ttf') format('truetype'),
        url('Exo2-BoldItalic.otf') format('otf');
    font-weight: 700;
    font-style: italic;
}
